import * as React from "react"
interface Props{
    fill: string
}
export const MascotaIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={56}
        height={50}
        fill="none"
    >
        <path
            fill={fill}
            d="M37.333 12.457a6.222 6.222 0 1 0 0-12.445 6.222 6.222 0 0 0 0 12.445Zm0-9.334a3.111 3.111 0 1 1 0 6.222 3.111 3.111 0 0 1 0-6.222ZM12.444 18.68a6.222 6.222 0 1 0-12.444 0 6.222 6.222 0 0 0 12.444 0ZM6.222 21.79a3.112 3.112 0 1 1 0-6.223 3.112 3.112 0 0 1 0 6.223Zm43.556-9.333a6.222 6.222 0 1 0 0 12.444 6.222 6.222 0 0 0 0-12.444Zm0 9.333a3.112 3.112 0 1 1 0-6.224 3.112 3.112 0 0 1 0 6.224Zm-31.111-9.333a6.223 6.223 0 1 0 0-12.446 6.223 6.223 0 0 0 0 12.446Zm0-9.334a3.11 3.11 0 1 1 0 6.222 3.11 3.11 0 0 1 0-6.222ZM28 18.68a9.366 9.366 0 0 1 8.704 6.017 9.371 9.371 0 0 0 3.925 4.712 9.239 9.239 0 0 1 4.388 9.278 9.391 9.391 0 0 1-7.363 7.803 9.542 9.542 0 0 1-1.878.19c-1.36 0-2.703-.31-3.926-.906a8.88 8.88 0 0 0-7.7 0 8.956 8.956 0 0 1-3.928.906 9.547 9.547 0 0 1-1.879-.19 9.392 9.392 0 0 1-7.362-7.805 9.238 9.238 0 0 1 4.39-9.276 9.373 9.373 0 0 0 3.925-4.713A9.366 9.366 0 0 1 28 18.68Zm0-3.111a12.421 12.421 0 0 0-11.613 8.023 6.321 6.321 0 0 1-2.635 3.16A12.388 12.388 0 0 0 7.899 39.11 12.446 12.446 0 0 0 20.222 49.79c1.83 0 3.636-.416 5.281-1.215a5.769 5.769 0 0 1 4.993 0c1.646.8 3.451 1.215 5.28 1.215A12.447 12.447 0 0 0 48.1 39.11a12.389 12.389 0 0 0-5.851-12.358 6.32 6.32 0 0 1-2.635-3.16A12.421 12.421 0 0 0 28 15.567Z"
        />
    </svg>
)
