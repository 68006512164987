import React from 'react';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {ListSlideItem} from "./ListSlider";
import {CustomTypography} from "../Label/Label";
import {CustomButton} from "../Buttons/Buttons";
import {eventScrollDown} from "../../utils/utils";

type ItemSwiperProps = {
    item: ListSlideItem
    brand?: any
    index: number;
}

const ItemSwiper: React.FC<ItemSwiperProps> = ({item, brand, index}) => {
    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down("sm"));

    return (
        <Box sx={{
            display: 'flex',
            width: {xs: '100%', md: '100%'},
            height: {xs: '100%'},
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            //borderBottomLeftRadius: '40px',
            //borderBottomRightRadius: '40px',
            position: 'relative'
            //borderTopLeftRadius: '40px',
            //borderTopRightRadius: '40px',
        }}>
            <Box
                component={'img'}
                src={isMobile ? item.images[1] : item.images[0]}
                width={'100%'}
                sx={{
                    borderBottomLeftRadius: '40px',
                    borderBottomRightRadius: '40px',
                    backgroundSize: {xs: 'cover', sm: "cover", md: 'cover'}
                }}
                height={'100%'}
            />

            <Box sx={{
                padding: {
                    xs: '0 0 0 0',
                    sm: '0 0px 0px 70px',
                    md: '0 0 0 0'
                },
                position: 'absolute',
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
            }}>
                <Box sx={{
                    display: "inline-flex",
                    padding: {xs: "35px 0px 0px 20px", sm: "40px 0px 0px 30px", md: "65px 0px 0px 100px"},
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: 'flex-start',
                    gap: "16px",
                    position: 'absolute',
                    width: '100%', height: '100%',
                }}>
                    <Typography sx={{
                        width: isTablet && !isMobile ? '453px' : isMobile ? "85%" : "593px",
                        color: index === 0 ? "#212121" : "#FFF",
                        justifyContent: 'flex-start',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Roboto",
                        fontSize: isTablet ? '36px' : "48px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                        letterSpacing: "0.5px",
                    }} dangerouslySetInnerHTML={{
                        __html: item.title
                    }}/>

                    {index === 0 ?
                        <CustomTypography style={{
                            width: isTablet && !isMobile ? '493px' : isMobile ? "90%" : "493px",
                            color: index === 0 ? "#212121" : "#FFF",
                            fontFamily: "Roboto",
                            justifyContent: 'flex-start',
                            fontSize: isTablet ? '20px' : "24px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                        }}>{item.description}</CustomTypography>
                        :
                        <Typography
                            sx={{
                                width: isTablet && !isMobile ? '493px' : isMobile ? "90%" : "493px",
                                color: index === 0 ? "#212121" : "#FFF",
                                fontFamily: "Roboto",
                                justifyContent: 'flex-start',
                                fontSize: isTablet ? '20px' : "24px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: item.description
                            }}/>
                    }


                    <CustomButton size='large' variant={index === 0 ? 'contained' : 'outlined'} style={{
                        display: "flex",
                        marginTop: 20,
                        width: "250px",
                        backgroundColor: index === 0 ? item.color ? item.color : '#000' : "transparent",
                        color: 'white',
                        fontFamily: 'Roboto',
                        fontWeight: 600,
                        textTransform: "none",
                        padding: "10px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: isTablet && !isMobile ? '20px' : "20px",
                        borderRadius: "24px",
                    }} onClick={() => {
                        eventScrollDown(index === 0 ? "section-asistencias" : "section-Benefits")

                    }} text={index === 0 ? "Conocer las asistencias" : "Conocer los beneficios"}></CustomButton>
                </Box>

            </Box>

        </Box>
    );
};
export default ItemSwiper;
