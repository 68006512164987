import * as React from "react"

interface Props{
    fill: string
}
export const GruaIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={74}
        height={55}
        fill="none"
    >
        <path
            fill={fill}
            stroke={fill}
            d="m70.835 27.077-5.186-2.228-4.332-8.437a6.15 6.15 0 0 0-5.465-3.362h-.542l-.969-3.472a.994.994 0 0 0-1.01-.71h-2.887a.995.995 0 0 0-1.008.71l-.97 3.472h-2.951a1.093 1.093 0 0 0-1.046 1.1v9.277L30.74 33.212H2.015A.996.996 0 0 0 1 34.23v7.74c0 1.985 1.585 3.64 3.57 3.64h1.501a7.59 7.59 0 0 0-.053.882 7.863 7.863 0 0 0 7.863 7.867 7.867 7.867 0 0 0 7.868-7.862 7.66 7.66 0 0 0-.054-.888h29.37a7.598 7.598 0 0 0-.054.883 7.863 7.863 0 0 0 7.863 7.867 7.867 7.867 0 0 0 7.868-7.862 7.66 7.66 0 0 0-.053-.888h2.74c1.986 0 3.571-1.654 3.571-3.639V30.384a3.583 3.583 0 0 0-2.165-3.307ZM51.242 10.959h1.293l.606 2.091h-2.506l.607-2.09Zm4.61 4.182a4.043 4.043 0 0 1 3.611 2.195l3.721 7.214H46.56v-9.409h9.291ZM44.469 25.992v7.22H34.35l10.118-7.22ZM13.883 52.244A5.775 5.775 0 0 1 9.8 42.39a5.774 5.774 0 0 1 9.857 4.082 5.78 5.78 0 0 1-5.774 5.773Zm0-13.597a7.905 7.905 0 0 0-7.271 4.87H4.57a1.52 1.52 0 0 1-1.478-1.548v-6.666h41.377v8.214H21.155a7.902 7.902 0 0 0-7.272-4.87Zm44.994 13.597a5.775 5.775 0 0 1-5.663-6.899 5.773 5.773 0 1 1 5.663 6.9ZM70.909 41.97a1.519 1.519 0 0 1-1.48 1.548h-3.281a7.862 7.862 0 0 0-11.648-3.54 7.862 7.862 0 0 0-2.896 3.54H46.56V26.641h18.125l5.325 2.324a1.54 1.54 0 0 1 .899 1.419v11.585Z"
        />
        <path
            fill={fill}
            fillRule="evenodd"
            stroke={fill}
            d="m11 7.512 28.209 20.193-1.145 1.596L9.855 9.108 11 7.512Z"
            clipRule="evenodd"
        />
        <path
            fill={fill}
            fillRule="evenodd"
            stroke={fill}
            strokeWidth={0.5}
            d="M7.055 9.194a3.11 3.11 0 1 0 0-6.218 3.11 3.11 0 0 0 0 6.218Zm0 1.964a5.073 5.073 0 1 0 0-10.146 5.073 5.073 0 0 0 0 10.146Z"
            clipRule="evenodd"
        />
        <path
            fill={fill}
            fillRule="evenodd"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth={0.5}
            d="M6.4 16.976a3.436 3.436 0 0 0 0 6.873c.58 0 1.47-.182 2.188-.699.667-.48 1.248-1.29 1.248-2.738a.982.982 0 0 1 1.964 0c0 2.088-.892 3.487-2.065 4.332-1.123.808-2.442 1.068-3.335 1.068a5.4 5.4 0 0 1 0-10.8.982.982 0 0 1 0 1.964Z"
            clipRule="evenodd"
        />
        <path
            fill={fill}
            fillRule="evenodd"
            stroke={fill}
            d="M6 17.012v-7h2v7H6Z"
            clipRule="evenodd"
        />
    </svg>
)
