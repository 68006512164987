import * as React from "react"

interface Props{
    fill: string
}
export const CorazonIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={72}
        height={59}
        fill="none"
    >
        <path
            fill={fill}
            d="M66.26 5.732C62.61 2.085 57.636.323 52.248.758c-4.58.373-9.16 2.342-12.746 5.493C34.902 2.105 30.57.012 26.633.012c-5.265 0-10.198 2.031-13.887 5.741a19.55 19.55 0 0 0-5.74 13.886c0 1.928.269 3.71.787 5.575H0v2.881h8.912c.953 2.073 2.238 3.814 3.834 5.41l.02.02 25.742 24.27c.27.269.642.393.995.393.352 0 .725-.124.994-.393l25.741-24.27c.021 0 .021-.02.021-.02C69.95 29.814 72 24.882 72 19.617c0-5.264-2.031-10.196-5.74-13.886Zm-2.011 25.7L39.523 54.748 14.798 31.432a16.19 16.19 0 0 1-2.59-3.337h9.077c.539 0 1.036-.31 1.285-.787l2.819-5.534 3.564 10.653c.187.518.643.912 1.182.974.56.062 1.098-.166 1.388-.622l3.917-5.907h3.337l4.705 10.985c.228.539.746.87 1.326.87h.063c.6-.02 1.119-.414 1.326-.995l5.554-16.6 3.793 6.28c.27.434.725.704 1.244.704h10.03a16.368 16.368 0 0 1-2.57 3.316Zm-6.632-6.26-4.974-8.269a1.47 1.47 0 0 0-1.41-.683 1.493 1.493 0 0 0-1.223.974l-5.347 15.979-3.585-8.353a1.424 1.424 0 0 0-1.327-.87h-5.077c-.477 0-.933.228-1.203.643l-2.714 4.082-3.731-11.108a1.448 1.448 0 0 0-1.264-.995c-.58-.042-1.14.27-1.41.787l-3.958 7.814H10.86c-.622-1.865-.933-3.648-.933-5.575 0-4.498 1.741-8.705 4.892-11.855 3.15-3.15 7.357-4.87 11.834-4.87 3.42 0 7.523 2.196 11.876 6.383.56.539 1.471.539 2.03-.021 3.234-3.212 7.607-5.244 11.96-5.596 4.538-.373 8.704 1.099 11.73 4.124a16.632 16.632 0 0 1 4.89 11.835c0 1.927-.33 3.71-.932 5.575h-10.59Z"
        />
    </svg>
)
