import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import QuestionsPlus from "../../assets/Icons/QuestionsPlus";


type AccordeonCustomizedProps = {
    data?: Array<any>;
}
const AccordeonCustomized: React.FC<AccordeonCustomizedProps> = ({data}) => {
    const [expanded, setExpanded] = useState<any>([]);
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]); // Agrega el panel abierto al arreglo
        } else {
            const reduce = expanded.filter(((item: any) => item !== panel));
            setExpanded(reduce); // Elimina el panel cerrado del arreglo
        }
    }

    useEffect(() => {
        if (data && data?.length > 0) {
            setExpanded([]);
            //scrollToTop();
        }
    }, [data]);

    return (
        <Box
            sx={{
                width: '90%',
                maxWidth: '1140px',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                }}

            >
                <Typography
                    color={'var(--black, #212121)'}
                    textAlign={'center'}
                    fontSize={{xs:'32px',sm:'32px'}}
                    fontStyle={'normal'}
                    fontWeight={700}
                    sx={{marginBottom:3}}
                >
                    Preguntas frecuentes
                </Typography>

                {data && data?.length > 0 && data.sort((a,b) => a?.order - b?.order).map((items, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                            borderTop: index === 0 ? '2px solid gray' : "",
                            borderBottom: '2px solid gray'
                        }}>
                            <Accordion
                                sx={{
                                    width:'100%',
                                    margin: 0,
                                    padding:0,
                                    boxShadow: 'unset',
                                    borderRadius: '0px',
                                    textAlign: 'left',
                                }}

                                key={index} expanded={expanded.includes(index)} onChange={handleChange(index)}>
                                <AccordionSummary
                                    sx={{
                                        margin:0,
                                        padding:0,
                                        display:'flex',
                                        width:'100%'}}
                                    expandIcon={expanded.includes(index) ? < RemoveIcon /> : <Box sx={{padding:.1}}><QuestionsPlus width={"16"} height={"16"}/></Box>}
                                >
                                    <Typography
                                        color={'var(--black-75, #595959)'}
                                        fontSize={'16px'}
                                        fontWeight={700}
                                        sx={{
                                            textWrap: 'balance',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            marginRight: '12px'
                                        }}
                                    >{items.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{paddingLeft:0}}>
                                    <Typography
                                        color={'var(--black, #212121)'}
                                        fontSize={'16px'}
                                        fontWeight={400}
                                        dangerouslySetInnerHTML={{__html: items.description}}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })}
            </Box>
        </Box>
    )
};

export default AccordeonCustomized;
