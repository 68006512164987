import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {useConfigurationStore} from "../../store/useConfigurationStore";

const Footer: React.FC = () => {
    const { selectedConfiguration } = useConfigurationStore();

    return (
        <>
            {
                !selectedConfiguration.loading &&
              <Box
                component={'footer'}
                sx={{
                    display: "flex",
                    width: "100%",
                    height: {xs:'auto',sm:"200px"},
                    padding: "27px 0px",
                    justifyContent: "center",
                    gap: "10px",
                    borderBottom: "1px solid #F0F0F0",
                    background: "var(--Black, #212121)",
                    boxShadow: "4px 6px 16px 0px rgba(33, 33, 33, 0.05)"

                }}
                bottom="0px"
              >
                <Container
                  maxWidth={'xl'}
                  style={{
                      width: '100%',
                      padding: "27px 0px",
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                  }}>

                    {
                        selectedConfiguration?.url==="bc/general"?
                            <Grid container spacing={2}  marginTop="0" marginLeft="0px" sx={{
                                justifyContent:'center',
                                alignItems:'center',
                                display:'flex',
                                width: '100%',
                            }}>
                                <Grid item xs={12} sm={5}>
                                    <Typography
                                        variant={'subtitle2'}
                                        color={'white'}
                                        fontWeight={'400'}
                                        sx={{
                                            cursor: 'pointer',
                                            color: "#FFF",
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "17px /* 121.429% */",
                                        }}
                                        fontSize={"16px"}
                                    >
                                        {'Aviso Legal | Despachos de Cobranza | Aclaraciones | Aviso de Privacidad | Leyendas de Riesgo \n' +
                                            'Todos los derechos reservados para Bradescard México S. de R.L.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} sx={{
                                    justifyContent: {xs:'center',sm:'flex-end'},display:'flex'}}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.logo[1]}
                                        sx={{
                                            width: {xs: 180, md: 160},
                                            maxWidth: {xs: 180, md: 180},
                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>

                            </Grid>
                            :
                            <Grid container spacing={selectedConfiguration?.url ==="asistenciatotal/gcc"? 1:3}  marginTop="0" marginLeft="0px" sx={{
                                justifyContent:'center',
                                alignItems:'center',
                                display:'flex',
                                width: '100%',
                            }}>

                                <Grid item xs={12} sm={4} md={4.8}>
                                    <Typography
                                        variant={'subtitle2'}
                                        color={'white'}
                                        fontWeight={'400'}
                                        sx={{
                                            cursor: 'pointer',
                                            color: "#FFF",
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "17px /* 121.429% */",
                                        }}
                                        fontSize={"16px"}
                                    >
                                        {'Aviso Legal | Despachos de Cobranza | Aclaraciones | Aviso de Privacidad | Leyendas de Riesgo \n' +
                                            'Todos los derechos reservados para Bradescard México S. de R.L.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={selectedConfiguration?.url ==="asistenciatotal/gcc"?3:4} md={4} sx={{
                                    justifyContent: {xs:'center',sm:'flex-end'},display:'flex'}}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.logo[1]}
                                        sx={{
                                            width: {xs: 180, md: 160},
                                            maxWidth: {xs: 180, md: 180},
                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={selectedConfiguration?.url ==="asistenciatotal/gcc"?4.5:3.4} md={3} sx={{justifyContent:'center',display:'flex',marginTop:{xs:2,sm:0}}}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.sublogo[1]}
                                        sx={{
                                            width: {xs: 'auto', md: 'auto'},

                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>
                            </Grid>
                    }

                </Container>
              </Box>
            }
        </>

    )
}
export default Footer
