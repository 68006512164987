import React from 'react';
import {styled} from 'styled-components';
import logo from '../../../assets/BrandsImgs/CA/C&A.svg'
import logo2 from '../../../assets/BrandsImgs/CA/Clubdebeneficios.png'

import {
    Toolbar,
    Stack,
    Divider, Box,
    Link as LinkMui
} from '@mui/material';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {CustomTypography} from "../../Label/Label";
import {CustomButton} from "../../Buttons/Buttons";
import IconPhoneCustom from "./../../../assets/Icons/IconlPhoneCustom";
import {useConfigurationStore} from "../../../store/useConfigurationStore";
import {PhoneColor, PhoneIconColor,validatePhoneNumber} from "../../../utils/utils";


const MenuContentElement = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
`;

const MenuSeccionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;

interface MenuContentProps {
    setOpenModal: any;
    body: Array<any>;
    visibleMenu: boolean;
    isAuthState: boolean;
}

const MenuContent: React.FC = () => {
    const {selectedConfiguration} = useConfigurationStore();
    const callPhone = (phone: string) => {
        const phoneNumber = phone; // número de teléfono al que se realizará la llamada
        window.location.href = `tel:${phoneNumber}`;
    };
    return (
        <React.Fragment>


            <Toolbar sx={{
                display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex'},
                justifyContent: 'space-between',
                gap: ' 8rem'
            }}>
                {
                    selectedConfiguration?.url === "bc/general" ?
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            divider={<Divider orientation="vertical" flexItem/>}
                        >
                                  <span style={{flexShrink: 0}}>
                                    <LinkMui
                                        underline="none"
                                    >
                                      <Box component={'img'} src={selectedConfiguration.logo[0]}
                                           sx={{width: 'auto', height: '49px'}}/>
                                    </LinkMui>
                                </span>

                        </Stack>
                        :
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            divider={<Divider orientation="vertical" flexItem/>}
                        >
                                  <span style={{flexShrink: 0}}>
                                    <LinkMui
                                        underline="none"
                                    >
                                      <Box component={'img'} src={selectedConfiguration.logo[0]}
                                           sx={{width: 'auto', height: '49px'}}/>
                                    </LinkMui>
                                </span>
                                        <span style={{flexShrink: 0, marginLeft: "20px"}}>
                                      <LinkMui underline="none">
                                          <Box component={'img'} src={selectedConfiguration?.sublogo[0]}
                                               sx={{width: 'auto', height: '49px'}}/>
                                    </LinkMui>
                                </span>
                        </Stack>
                }

                <MenuSeccionWrapper>
                    <div/>
                </MenuSeccionWrapper>
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <CustomTypography style={{
                        color: "#6D6E71",
                        textAlign: "right",
                        fontFamily: 'Roboto',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                    }}>{"Solicita tus asistencias al"}</CustomTypography>
                    <CustomTypography style={{
                        color: selectedConfiguration ? PhoneColor(selectedConfiguration?.url,selectedConfiguration) : "#6D6E71",
                        textAlign: "right",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontSize: "20px",
                        fontFamily: 'Roboto',
                        fontWeight: 700,
                        lineHeight: "normal",
                    }}>{validatePhoneNumber(selectedConfiguration?.phonenumber)}</CustomTypography>
                </Box>
            </Toolbar>

            <Toolbar sx={{
                display: {xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none'},
                justifyContent: 'space-between',
                padding: 0,
                gap: ' 8rem'
            }}>
                <MenuSeccionWrapper>
                    {
                        selectedConfiguration?.url === "bc/general" ?
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                divider={<Divider orientation="vertical" flexItem/>}
                            >

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Box component={'img'} src={selectedConfiguration?.logo[0]}
                                         sx={{width: '100px', height: 28}}/>
                                </Box>
                            </Stack>
                            :
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                divider={<Divider orientation="vertical" flexItem/>}
                            >

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Box component={'img'} src={selectedConfiguration?.logo[0]}
                                         sx={{width: 'auto', height: 28}}/>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Box component={'img'} src={selectedConfiguration?.sublogo[0]}
                                         sx={{width: 'auto', height: 28}}/>
                                </Box>
                            </Stack>
                    }


                    <Box
                        onClick={() => {
                            callPhone(selectedConfiguration?.phonenumber)
                        }}
                        sx={{
                            width: {xs: '40px', sm: '80px'},
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: "16px",
                            cursor: 'pointer',
                            padding: "10px 26px",
                            justifyContent: "center",
                            gap: "8px",
                            borderRadius: "24px",
                            border: `1.5px solid var(--ca-verde, ${PhoneIconColor(selectedConfiguration?.url,selectedConfiguration)})`,
                            opacity: 0.8,
                        }}>
                        <IconPhoneCustom width={16} height={16} fill={PhoneIconColor(selectedConfiguration?.url,selectedConfiguration)}/>
                    </Box>
                </MenuSeccionWrapper>
            </Toolbar>


        </React.Fragment>
    );
};

export default MenuContent;
