import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import {Box, CircularProgress, Typography} from '@mui/material';
import LogoBase from '../../assets/favicos/favbradescard.png'
import {
  useConfigurationStore,
} from '../../store/useConfigurationStore';
import ListSlider from "../../components/ListSlider/ListSlider";
import {SolicitarAsistencia} from "../../components/SolicitarAsistencia";
import {ComenzarTusBeneficios} from "../../components/ComenzarTusBeneficios";
import {Asistencias} from "../../components/Asistencias";
import {BenefitsInformation} from "../../components/BenefitsInformation/BenefitsInformation";
import {PreguntasFrecuentes} from "../../components/PreguntasFrecuentes";
import {getCompany} from "../../api/getCompany";
import {BenefitsOptionsSwiper} from "../../components/BenefitsOptions/BenefitsOptionsSwiper";
import {validateAhorroCompany, ValidateTitleDescByCompany} from "../../utils/utils";
import BenefitsDescriptionCYA from "../../components/CompanyDescriptionBenefit/BenefitsDescriptionCYA";
import CustomModal from "../../components/Modals/CustomModal";
import BenefitsDescriptionBodega from "../../components/CompanyDescriptionBenefit/BenefitsDescriptionBodega";

export const Home: React.FC = () => {
  const { socio,brandId } = useParams();
    const navigate = useNavigate()
    const { setConfiguration,selectedConfiguration } = useConfigurationStore();

    const [openModalBenefits, setOpenModalBenefits] = useState(false);

    const getBrand = async (id: string) => {
        const res : any = await getCompany(id)
        if (res.status===200){
            setConfiguration(res.data);
        }else {
            navigate("/bc/general")
        }
  };

  // Test de prueba de el estado que nos retorna basado en la pagina a la que accedio
  console.log('====================================');
  console.log(selectedConfiguration);
  console.log('====================================');

  useEffect(() => {
    if (socio && brandId) {
      getBrand(socio+"%2F"+brandId);
    } else {
       navigate("/bc/general")
    }
  }, [socio,brandId]);

    useEffect(() => {
        if (selectedConfiguration) {
            if (selectedConfiguration?.url) {
                document.title = ValidateTitleDescByCompany(selectedConfiguration?.url).title
                let descMeta:any = document.querySelector("meta[name='description']")
                descMeta.setAttribute("content", ValidateTitleDescByCompany(selectedConfiguration?.url).desc)
                let favicon:any = document.querySelector('link[rel="icon"]');
                let darkFavicon = ValidateTitleDescByCompany(selectedConfiguration?.url).icon ;
                favicon.href = darkFavicon;
            }
        }
    }, [selectedConfiguration?.url]);

  return (
    <Box
      sx={{
         // paddingBottom: '40px',
          backgroundSize: '20%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundPositionY: '35%',
      }}
    >
        {
            selectedConfiguration.loading ?
                <Box sx={{height:'100vh',width:'100%',alignItems:'center',justifyContent:'center'}}>
                    <Box sx={{ display: 'flex' ,gap:5,flexDirection:'column',alignItems:'center',width:'100%',justifyContent:'center',height:'100%'}}>
                        <CircularProgress size={50}/>
                        <Typography sx={{fontWeight:700,fontSize:26}}>
                            {"Buscando la información..."}
                        </Typography>
                    </Box>
                </Box>
                :
            <>
                <ListSlider/>

                <BenefitsInformation title={selectedConfiguration?.name}
                                     open={openModalBenefits}
                                     setOpen={setOpenModalBenefits}
                                     primaryColor={selectedConfiguration?.primaryColor}
                                     amount={selectedConfiguration?.cost} link={selectedConfiguration?.termsAndCoditions} description={selectedConfiguration?.description}
                />
                <BenefitsOptionsSwiper phoneNumber={selectedConfiguration?.phonenumber} primaryColor={selectedConfiguration?.primaryColor}/>
                <Asistencias />
                <SolicitarAsistencia />
                <ComenzarTusBeneficios />
                <PreguntasFrecuentes />

                {
                    openModalBenefits &&
                    <CustomModal open={openModalBenefits} handleClose={()=>{setOpenModalBenefits(false)}}>
                        {
                            validateAhorroCompany(selectedConfiguration?.url)
                        }
                    </CustomModal>
                }



            </>
        }


    </Box>
  );
};

// test0
