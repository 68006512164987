import { InterInstance } from './config';

export const getSupport = async (id: string) => {
    try {
        const  data  = await InterInstance.get(`support?companyId=${id}`, {
            //headers: { Authorization: `Bearer ${token}` },
        });
        return data.data;
    } catch (e) {
        return e;
    }
};