import {Home} from "../pages/Home/Home";
import {Route, Routes} from "react-router-dom";
import React from "react";
import NotFound from "../pages/NotFound/NotFound";
const Pages = () => {
  return(
      <Routes>
          <Route path='/:socio?/:brandId?' element={<Home />} />
          <Route path='/*' element={<NotFound />} />
      </Routes>
  )
}
export default Pages;
