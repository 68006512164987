import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from 'styled-components';

interface LabelProps {
  textType?: any;
  fontSize?: number;
  color?: string;
  url?: string;
  value: string;
  icon?: React.ReactNode;
}

const LabelSpanElement = styled.span`
  display: flex; 
  align-items: center;
  justify-content: center; 
  color: #000;
  cursor: pointer;
  top: 2px;
  position: relative;
  font-weight: 700;
`;

export const Label: React.FC<LabelProps> = ({ textType = "body1", fontSize = 700, color = '#039ECC', url, value, icon }) => {
  return (
    <Typography variant={textType} component={textType} style={{ fontWeight: fontSize }}>
      <a
        href={url}
        style={{
          color,
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3
        }}
      >
        {icon} {value}
      </a>
    </Typography>
  );
};

interface CustomTypographyProps {
  item?: string,
  handleClick?: any
  style?: any;
  children?: React.ReactNode;
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({ item, handleClick, style, children }) => {
  return (
    <LabelSpanElement onClick={handleClick} style={style}> {item} {children} </LabelSpanElement>
  );
};

