import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'


import imgDesktopCA1 from '../assets/BrandsImgs/CA/desktop_slider_hero_ca.png'
import imgDesktopCA2 from '../assets/BrandsImgs/CA/desktop_slider_hero_2.png'
import imgMobileCA1 from '../assets/BrandsImgs/CA/mobile_slider_hero_ca.png'
import imgMobileCA2 from '../assets/BrandsImgs/CA/mobile_slider_hero_2.png'

import LogoCAHeaderOne from '../assets/BrandsImgs/CA/Clubdebeneficios.png'
import LogoCAHeaderTwo from '../assets/BrandsImgs/CA/C&A.svg'
import LogoCAFooterOne from '../assets/BrandsImgs/CA/C&AWhite.svg'

import imgDesktopAB1 from '../assets/BrandsImgs/CA/desktop_slider_hero_ca.png'
import imgDesktopAB2 from '../assets/BrandsImgs/CA/desktop_slider_hero_2.png'
import imgMobileAB1 from '../assets/BrandsImgs/CA/mobile_slider_hero_ca.png'
import imgMobileAB2 from '../assets/BrandsImgs/CA/mobile_slider_hero_2.png'

import LogoABHeaderOne from '../assets/BrandsImgs/AB/AsitenciaGroup.svg'
import LogoABHeaderTwo from '../assets/BrandsImgs/AB/Bodega_Aurrera_logo.svg'
import LogoABFooterOne from '../assets/BrandsImgs/AB/Bodega_Aurrera_logo_white.svg'

import LogoPAPHeaderOne from '../assets/BrandsImgs/PAP/AsistenteGroup.svg'
import LogoPAPHeaderTwo from '../assets/BrandsImgs/PAP/promoda_logo.svg'
import LogoPAPFooterOne from '../assets/BrandsImgs/PAP/promoda_logoWhite.svg'

import LogoSAHeaderOne from '../assets/BrandsImgs/S/Group.svg'
import LogoSAHeaderTwo from '../assets/BrandsImgs/S/SHASANEW.svg'
import LogoSAFooterOne from '../assets/BrandsImgs/S/SHASANEWWHITE.svg'


import {dummies_slider} from "../utils/constants";

// CA = C&A, AB = Asistencia Bodega,
// PAP = Pro Asistencia Promoda, S = Shasa
// GT = Guardian Total

export type TypeConfiguration = 'CA' | 'AB' | 'PAP' | 'S' | 'GT';

export interface SlideProps {
  title: string;
  description: string;
  buttonTitle: string;
  image: string;
  image_mobile: string;
  type: string;
}
interface PageConfiguration {
  // Aqui deben ir todos los atributos que comparten todas las paginas
  loading:boolean;
  sliderData:  SlideProps[],
  logo_header_one:string;
  logo_header_two:string;
  logo_footer_one:string;
  logo_footer_two:string;
  background: string;
  phoneTextColor: string;
  iconColor: string;
  verMasTextColor: string;
  linkTerminosTextColor: string;
  priceTextColor: string;
  buttonSliderColor: string;
  beneficioTelefonoColor: string;
  beneficionSeleccionado: string;
  botonSolicitarAsistencia: string;
  createTime: string;
  updateTime: string;
  deteleTime: string | null;
  id: string;
  name: string;
  description: string;
  logo: string [];
  sublogo: string [];
  phonenumber: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  quaternaryColor: string;
  cost?:number,
  termsAndCoditions?:string;
  url?:string;
}

interface Configuration {
  configurationState: {
    // De ser necesario extender con otra interface para atributos especificos por pagina
    CA: PageConfiguration;
  };
  selectedConfiguration: PageConfiguration;
  setConfiguration: (data:any) => void;
}

interface InitialConfigState {
  CA: PageConfiguration;
}

const initialConfigurationState: InitialConfigState = {
  CA: {
    // Agregar aqui el estado inicial para la pagina C&A
    loading: false,
    sliderData: dummies_slider({
      image_desktop_1: imgDesktopCA1,
      image_mobile_1: imgMobileCA1,
      image_desktop_2: imgDesktopCA2,
      image_mobile_2: imgMobileCA2
    }),
    logo_header_one: LogoCAHeaderOne,
    logo_header_two: LogoCAHeaderTwo,
    logo_footer_one: LogoCAFooterOne,
    logo_footer_two: "",
    background: '#398C9C',
    phoneTextColor: '#398C9C',
    iconColor: '#398C9C',
    verMasTextColor: '#398C9C',
    linkTerminosTextColor: '#398C9C',
    priceTextColor: '#398C9C',
    buttonSliderColor: '#398C9C',
    beneficioTelefonoColor: '#398C9C',
    beneficionSeleccionado: '#212121',
    botonSolicitarAsistencia: '#398C9C',
    createTime: "",
    updateTime: "",
    deteleTime: "",
    id: "",
    name: "",
    description: "",
    logo: [],
    sublogo: [],
    phonenumber: "",
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    quaternaryColor: "",
    cost:0,
    termsAndCoditions:"",
    url:"",
  }
};

export const useConfigurationStore = create(persist<Configuration>((set, get) => ({
  // Default states
  configurationState: initialConfigurationState,
  selectedConfiguration: {
    loading: true,
    sliderData: dummies_slider({image_desktop_1: "", image_mobile_1: "", image_desktop_2: "", image_mobile_2: ""}),
    logo_header_one: LogoCAHeaderOne,
    logo_header_two: LogoCAHeaderTwo,
    logo_footer_one: LogoCAFooterOne,
    logo_footer_two: "",
    background: '',
    phoneTextColor: '',
    iconColor: '',
    verMasTextColor: '',
    linkTerminosTextColor: '',
    priceTextColor: '',
    buttonSliderColor: '',
    beneficioTelefonoColor: '',
    beneficionSeleccionado: '',
    botonSolicitarAsistencia: '',
    createTime: "",
    updateTime: "",
    deteleTime: "",
    id: "",
    name: "",
    description: "",
    logo: [],
    sublogo: [],
    phonenumber: "",
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    quaternaryColor: "",
    cost:0,
    termsAndCoditions:"",
    url:""
  },
  //==========================================
  setConfiguration: (data: any) => {
    set({selectedConfiguration: {...initialConfigurationState.CA, ...data}});
  },
}),
    {
      name: 'brand-storage',
      storage: createJSONStorage(() => sessionStorage)
    }))
