import * as React from "react";

interface PropsI {
    fill?: string;
    stroke?:string;
}

export const IconCine = ({fill = "#398C9C",stroke='#398C9C'}: PropsI) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
        <path
            d="M17.8332 1.88889V2.77778M17.8332 6.33333V7.22222M17.8332 10.7778V11.6667M17.8332 15.2222V16.1111M7.16656 9.88889H13.3888M7.16656 12.5556H10.7221M2.27767 1C1.54167 1 0.944336 1.59733 0.944336 2.33333V5.9197C1.48566 6.23138 1.9353 6.68021 2.24794 7.22098C2.56059 7.76175 2.7252 8.37536 2.7252 9C2.7252 9.62464 2.56059 10.2383 2.24794 10.779C1.9353 11.3198 1.48566 11.7686 0.944336 12.0803V15.6667C0.944336 16.4027 1.54167 17 2.27767 17H22.7221C23.4581 17 24.0554 16.4027 24.0554 15.6667V12.0803C23.5141 11.7686 23.0645 11.3198 22.7518 10.779C22.4392 10.2383 22.2746 9.62464 22.2746 9C22.2746 8.37536 22.4392 7.76175 22.7518 7.22098C23.0645 6.68021 23.5141 6.23138 24.0554 5.9197V2.33333C24.0554 1.59733 23.4581 1 22.7221 1H2.27767Z"
            stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
