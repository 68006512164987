import React from "react";


const NotFound: React.FC = () => {

    return (
        <div style={{display: 'flex', width: '100%', height: '100vh',alignItems:'center',justifyContent:'center', backgroundColor: 'white'}}>
            <p>{ "Página no encontrada."}</p>
        </div>
    )
}
export default NotFound
