import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";

import {useConfigurationStore} from "../../store/useConfigurationStore";
import IconoUno from "../../assets/images/icono_uno.svg";
import IconoDos from "../../assets/images/icono_dos.svg";
import IconoTres from "../../assets/images/icono_tres.svg";
import {textCompanySocioName, validateNameId, validatePhoneNumber} from "../../utils/utils";
import React from "react";

interface beneficio {
    id: number
    text: string
    icon: string
}

 const beneficios = (phone="",company="")=> {
    let array :beneficio[] =
        [
            {
                id: 1,
                text: `Debes tener a la mano el número de tu tarjeta ${company}`,
                icon: IconoUno
            },
            {
                id: 2,
                text: `<span style='font-family: Roboto'>Llámanos al <b style='font-family: Roboto'>${phone}</b> y solicita el beneficio que deseas utilizar</span>`,
                icon: IconoDos
            },
            {
                id: 3,
                text: 'Nuestros asesores se encargaran de proporcionarte la información necesaria para que lo puedas redimir',
                icon: IconoTres
            }
        ]

     return array
 }


export const ComenzarTusBeneficios = () => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isMobile = useMediaQuery(breakpoints.down("sm"));

    const {selectedConfiguration} = useConfigurationStore()
    const callPhone = (phone:string) => {
        const phoneNumber = phone; // número de teléfono al que se realizará la llamada
        window.location.href = `tel:${phoneNumber}`;
    };
    return(
        <Box sx={{
            background: validateNameId(selectedConfiguration?.url,selectedConfiguration),
            width: '100%',
            height: isTablet ? '748px' : '576px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography sx={{
                fontSize: '32px',
                fontWeight: 700,
                color: '#FFFFFF',
                textAlign: 'center'
            }}>
                ¡Comienza a usar tus beneficios hoy mismo!
            </Typography>
            <Typography sx={{
                fontSize: '24px',
                fontWeight: 400,
                color: '#FFFFFF',
                textAlign: 'center',
                paddingTop: isTablet ? '0px' : '',
                paddingBottom: isTablet ? '30px' : '',
                paddingLeft: isTablet ? '30px' : '',
                paddingRight: isTablet ? '30px' : '',
                marginTop: '16px',
                marginBottom: '48px'
            }}>
                Sólo tienes que seguir 3 sencillos pasos
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '30px'
            }}>
                {
                    beneficios(validatePhoneNumber(selectedConfiguration?.phonenumber),textCompanySocioName(selectedConfiguration?.url)).map((beneficio,i)=>(
                        <Box key={beneficio.id} style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginBottom: '30px'
                        }}>
                            <Box
                                component="img"
                                sx={{
                                    width: '64px',
                                    height: '64px'
                                }}
                                alt="Numero uno icono"
                                src={beneficio.icon}
                            />



                            <Box component={"span"}
                                 onClick={()=>{
                                     i === 1 &&
                                     isMobile ? callPhone(selectedConfiguration?.phonenumber) :
                                     isTablet &&  callPhone(selectedConfiguration?.phonenumber)
                                 }}
                                sx={{
                                fontSize: '22px',
                                    fontFamily:'Roboto',
                                fontWeight: 400,
                                color: '#FFFFFF',
                                width: isTablet ? '246px' : '462px',
                                margin: '0px 0px 0px 25px'
                            }}
                                 dangerouslySetInnerHTML={{__html: beneficio.text}}

                            />
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}
