import * as React from "react";

interface PropsI {
    fill?: string;
    stroke?:string;
}

export const IconHogar = ({fill = "#398C9C",stroke='#398C9C'}: PropsI) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
        <path
            d="M3.92579 14.5948L8.56047 10.1633L8.51718 10.0544C8.0619 8.90846 8.34353 7.61776 9.23418 6.76592C9.80888 6.2166 10.5532 5.92851 11.311 5.92851C11.6449 5.92851 11.9818 5.98424 12.3077 6.09868L9.91934 8.38203L11.8106 10.3599L14.1989 8.07652C14.5249 9.15625 14.2228 10.3255 13.3949 11.1172C12.5042 11.969 11.2021 12.1924 10.0776 11.6859L9.97059 11.6376L6.11807 15.3459M3.92579 14.5948L3.67871 14.3494M3.92579 14.5948L3.92579 15.3459H6.11807M3.67871 14.3494L8.15147 10.0728C7.70365 8.82238 8.02757 7.43764 8.99286 6.51465C9.9875 5.56329 11.4404 5.31401 12.6943 5.87975L12.9296 5.98573L10.4109 8.39397L11.8206 9.86828L14.3393 7.46003L14.4348 7.69986C14.9438 8.97762 14.6298 10.4181 13.6347 11.3689C12.6694 12.2919 11.2712 12.5537 10.0422 12.0501L6.11807 15.7166M3.67871 14.3494V15.7166H6.11807M6.11807 15.3459V15.7166"
            stroke={stroke} strokeWidth="0.5"/>
        <path
            d="M22.3796 7.41647C23.0572 7.86824 22.3796 8.84706 21.7019 8.39529L20.0454 7.26588V15.3976C20.0454 15.6988 19.8196 16 19.5184 16H3.55603C3.17956 16 2.95368 15.6988 2.95368 15.3976V7.26588L1.2972 8.39529C0.619558 8.84706 -0.0580893 7.86824 0.619558 7.41647L11.1607 0.112941C11.3866 -0.0376471 11.6878 -0.0376471 11.8384 0.112941L22.3796 7.41647ZM18.916 14.7953V6.43765L11.5372 1.31765L4.15838 6.43765V14.7953H18.916Z"
            fill={fill}/>
    </svg>
)
