import * as React from "react"

interface Props{
    fill: string
}
export const HogarIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={72}
        height={52}
        fill="none"
    >
        <path
            stroke={fill}
            strokeWidth={1.5}
            d="m11.572 47.09 14.95-14.295-.14-.351a9.674 9.674 0 0 1 2.312-10.608 9.677 9.677 0 0 1 9.914-2.152l-7.704 7.365 6.1 6.38 7.705-7.365a9.663 9.663 0 0 1-2.594 9.808 9.67 9.67 0 0 1-10.7 1.834l-.345-.155-12.427 11.961M11.57 47.09l-.796-.791m.796.791v2.423h7.072m-7.869-3.214 14.428-13.795c-1.445-4.033-.4-8.5 2.714-11.477a10.784 10.784 0 0 1 11.94-2.048l.758.342-8.124 7.768 4.547 4.755 8.124-7.768.308.774a10.781 10.781 0 0 1-2.58 11.835A10.786 10.786 0 0 1 31.3 38.881L18.643 50.708m-7.869-4.41v4.41h7.869m0-1.196v1.196"
        />
        <path
            fill={fill}
            d="M71.095 23.935c2.185 1.457 0 4.614-2.186 3.157l-5.343-3.643v26.23c0 .971-.729 1.943-1.7 1.943H10.377c-1.214 0-1.943-.972-1.943-1.943v-26.23l-5.343 3.643C.905 28.55-1.28 25.392.905 23.935L34.907.377c.729-.486 1.7-.486 2.186 0l34.002 23.558ZM59.923 47.736V20.778L36.12 4.262l-23.8 16.515v26.959h47.602Z"
        />
    </svg>
)
