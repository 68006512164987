import React from "react";
import {Box, Grid,  Typography} from "@mui/material";
import BoxHeart from '../../assets/images/BoxHeart.png'
import {CostColor, TermCodColor} from "../../utils/utils";
import {useConfigurationStore} from "../../store/useConfigurationStore";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
interface BenefitsInformationI {
    open:boolean;
    setOpen:(open:boolean) => void;
    title?: string;
    description?: string;
    amount?: number;
    link?: string;
    primaryColor?: string;
}

export const download = (link: string) => {
    let element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('target', '_blank')
    element.setAttribute("rel", "noopener noreferre")
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export const BenefitsInformation: React.FC<BenefitsInformationI> = ({
                                                                        title = "Club de Beneficios",
                                                                        description = "",
                                                                        amount = 13099,
                                                                        link = "",
                                                                        primaryColor = '#398C9C',
    open,
    setOpen
                                                                    }) => {

    const {selectedConfiguration} = useConfigurationStore();


    return (

        <Box sx={{
            width: {xs: '100%', md: '100%'},
            height: {xs: '100%', md: '100%'},
            paddingTop: '80px',
            paddingBottom: '80px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '40px',
            display: 'inline-flex'
        }}>
            <Grid container spacing={0} sx={{
                width: {xs: '90%', md: '98%', lg: '1045.5px'},
                height: '100%',
                justifyContent: {xs: 'center', sm: 'flex-end'},
                alignItems: {xs: 'center', sm: 'flex-end'},
                gap: {xs: '48px', sm: 0},
                display: 'inline-flex'
            }}>
                <Grid item xs={12} sm={6} sx={{
                    width: {xs: '100%', md: '390px'},
                    height: {xs: '100%', md: '290px'},
                    padding: '24px',
                    justifyContent: 'center',
                    alignItems: {xs: 'flex-start', sm: 'flex-start'},
                    gap: '10px',
                    display: 'flex'
                }}>
                    <Box component={'img'} sx={{width: "266px", height: "266px"}} src={BoxHeart}/>
                </Grid>
                <Grid item xs={12} sm={6} sx={{
                    alignItems: {xs: 'center', sm: 'flex-start'},
                    display: 'flex', flexDirection: 'column',
                    gap: 3, justifyContent: 'center'
                }}>
                    <Typography sx={{
                        color: '#212121',
                        textAlign: {xs: 'center', sm: 'left'},
                        fontSize: {xs: '32px', sm: "32px"},
                        fontFamily: 'Roboto',
                        fontWeight: 700,
                        wordWrap: 'break-word'
                    }}>{`¿Qué es mi ${title}?`}
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: {xs: 'center', sm: 'flex-start'},
                        flexDirection: 'column',
                        gap: {xs: '33px', sm: 2, md:2}
                    }}>
                        <Typography
                            sx={{
                                display: 'flex',
                                fontFamily: 'Roboto',
                                textAlign: {xs: 'center', sm: 'left'},
                                color: '#505050', fontSize: '18px', wordWrap: 'break-word'
                            }}
                            dangerouslySetInnerHTML={{__html: description}}
                        />
                        <Box sx={{
                            display: 'flex', width: '100%', height: '100%',
                            gap:{xs:0,sm:.5,md:1},
                            alignItems: {xs:'center',sm:'flex-start'},
                            flexDirection:{xs:'column',md:'row'}
                        }}>
                            <Typography sx={{
                                textAlign: {xs: 'center',sm:'center', md: 'left'},
                                color: CostColor(selectedConfiguration?.url, selectedConfiguration),
                                fontSize: '36px',
                                fontFamily: 'Roboto',
                                fontWeight: 800,
                                wordWrap: 'break-word'
                            }}>${amount.toLocaleString("en-US")} al año</Typography>
                            <Box sx={{
                                display: 'flex', padding: {xs:'10px 0 0 0',sm:'0px 0px 20px 0px', md:'20px 0 0px 0px'}
                            }}>
                                <Box component={"span"} onClick={() => {
                                    setOpen(!open)
                                }} sx={{
                                    width: '100%',
                                    display: 'flex',
                                    textAlign: {xs: 'center', md: 'left'},
                                    color: CostColor(selectedConfiguration?.url, selectedConfiguration),
                                    cursor: "pointer",
                                    fontSize: '18px',
                                    fontFamily: 'Roboto',
                                    fontWeight: 500,
                                    textDecoration: 'underline',
                                    wordWrap: 'break-word'
                                }}>{"Conocer más"}
                                    <ArrowCircleRightIcon sx={{paddingLeft:'8px',color:CostColor(selectedConfiguration?.url, selectedConfiguration),}} />
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: {xs: 'center', sm: 'left'},
                            display: 'flex'
                        }}>
                            <Typography sx={{
                                textAlign: {xs: 'center', sm: 'left'},
                                color: '#505050',
                                fontSize: 18,
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>{"Ver"}&nbsp;
                            </Typography>
                            <Box component={"span"} onClick={() => {
                                download(link)
                            }} sx={{
                                textAlign: {xs: 'center', sm: 'left'},
                                color: TermCodColor(selectedConfiguration?.url, selectedConfiguration),
                                cursor: "pointer",
                                fontSize: 18,
                                fontFamily: 'Roboto',
                                fontWeight: 500,
                                textDecoration: 'underline',
                                wordWrap: 'break-word'
                            }}>{"Terminos y condiciones"}
                            </Box>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
