import * as React from "react"

interface Props{
    fill: string
}
export const DienteIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={55}
        height={57}
        fill="none"
    >
        <path
            fill={fill}
            d="m6.211 41.672.102.287a66.415 66.415 0 0 0 1.59 4.093c2.872 6.7 5.983 9.96 9.51 9.96 4.966 0 5.846-4.618 6.556-8.33.361-1.964.77-4.024 1.664-5.327l.082-.123.116-.15a1.609 1.609 0 0 1 1.228-.512 1.584 1.584 0 0 1 1.214.498c1.044 1.249 1.467 3.466 1.876 5.615.682 3.71 1.59 8.33 6.556 8.33 7.743 0 12.28-16.046 16.298-32.473a33.807 33.807 0 0 0 1.084-9.742c-.218-3.984-1.48-7.218-3.738-9.605A14.442 14.442 0 0 0 43.964.406C39.639-.917 34.91 1.69 31.11 3.776c-1.508.826-3.41 1.856-4.052 1.856a3.88 3.88 0 0 1-.607-.048 4.366 4.366 0 0 1-.792-.204 17.203 17.203 0 0 1-1.8-1.071C20.646 2.262 15.264-1.15 10.154.406c-6.228 1.91-12.86 7.47-9.018 23.147.996 4.093 2.203 8.91 3.574 13.446.478 1.645.99 3.22 1.501 4.673Zm4.94-38.005c3.608-1.098 8.186 1.795 10.873 3.52a14.774 14.774 0 0 0 2.49 1.406c.461.163.936.284 1.42.361.372.06.748.09 1.125.09 1.542 0 3.41-1.01 5.69-2.28 3.24-1.78 7.279-3.99 10.232-3.097a11.134 11.134 0 0 1 4.905 2.872c1.685 1.788 2.634 4.291 2.811 7.45a30.374 30.374 0 0 1-.996 8.746c-2.176 8.957-7.286 29.887-12.996 29.887-1.849 0-2.394-1.317-3.206-5.56-.491-2.572-.996-5.233-2.613-7.163a5.125 5.125 0 0 0-7.681 0c-.13.157-.253.32-.376.498l-.3.443v.034c-1.078 1.801-1.5 4.018-1.91 6.14-.812 4.243-1.364 5.56-3.206 5.56-1.842 0-4.196-2.804-6.379-7.893a62.921 62.921 0 0 1-1.412-3.636v-.055l-.13-.266c-.518-1.46-1.03-3.042-1.535-4.7-1.364-4.455-2.537-9.203-3.52-13.262C1.817 12.086 4.028 5.85 11.15 3.681v-.014Z"
        />
    </svg>
)
