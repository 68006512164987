import * as React from "react"

interface Props{
    fill: string
}
export const AmbulanciaIcon = ({fill}:Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={71}
        height={66}
        fill="none"
    >
        <path
            fill={fill}
            stroke={fill}
            strokeWidth={0.5}
            d="M6.27 57.437h2.634c.549 4.282 4.171 7.575 8.563 7.575 4.39 0 8.013-3.293 8.562-7.575h18.223c.549 4.282 4.172 7.575 8.563 7.575 4.39 0 8.014-3.293 8.562-7.575h5.05c2.415 0 4.281-1.976 4.281-4.28v-9.661a4.25 4.25 0 0 0-2.964-4.062l-7.684-2.415-6.038-15.588c-.22-.44-.549-.769-1.097-.769H40.959V17.37c0-2.745-2.306-5.05-5.05-5.05-2.744 0-5.05 2.305-5.05 5.05v3.293H6.27C3.415 20.662 1 22.967 1 25.932v26.346c.11 2.854 2.415 5.16 5.27 5.16Zm11.197 5.38c-3.513 0-6.258-2.855-6.258-6.258s2.854-6.257 6.258-6.257c3.512 0 6.257 2.854 6.257 6.257 0 3.403-2.745 6.257-6.257 6.257Zm35.458 0c-3.513 0-6.258-2.855-6.258-6.258s2.854-6.257 6.258-6.257c3.512 0 6.257 2.854 6.257 6.257 0 3.403-2.745 6.257-6.258 6.257Zm4.5-26.127H46.558V22.967h5.488l5.38 13.723Zm-24.26-19.321a2.718 2.718 0 0 1 2.744-2.745 2.718 2.718 0 0 1 2.745 2.745v3.293h-5.38V17.37h-.11Zm-29.75 8.453a2.952 2.952 0 0 1 2.964-2.964h37.982v14.93c0 .658.55 1.207 1.209 1.207h13.392l8.124 2.525c.768.22 1.317.988 1.317 1.866v9.66a1.968 1.968 0 0 1-1.976 1.976h-5.05c-.658-4.171-4.171-7.355-8.562-7.355-4.282 0-7.904 3.184-8.563 7.355H26.03c-.659-4.171-4.171-7.355-8.562-7.355-4.391 0-7.904 3.184-8.563 7.355H6.269a2.952 2.952 0 0 1-2.964-2.964V25.822h.11Z"
        />
        <path
            fill={fill}
            stroke={fill}
            strokeWidth={0.5}
            d="M25.92 45.143c5.27 0 9.551-4.281 9.551-9.55 0-5.27-4.281-9.551-9.55-9.551-5.27 0-9.551 4.281-9.551 9.55 0 5.27 4.281 9.55 9.55 9.55Zm0-16.796c3.952 0 7.246 3.293 7.246 7.245s-3.294 7.246-7.246 7.246c-3.952 0-7.245-3.294-7.245-7.246 0-3.952 3.293-7.245 7.245-7.245ZM35.91 7.38c.658 0 1.207-.55 1.207-1.208V2.22c0-.659-.549-1.208-1.208-1.208-.658 0-1.207.55-1.207 1.208v3.952c0 .658.549 1.207 1.207 1.207ZM44.033 10.672c.33 0 .549-.11.768-.329l2.855-2.854c.439-.44.439-1.208 0-1.647-.44-.439-1.208-.439-1.647 0l-2.854 2.855c-.44.439-.44 1.207 0 1.646.22.22.549.33.878.33ZM26.907 10.343c.22.22.549.33.768.33.22 0 .55-.11.769-.33.439-.439.439-1.207 0-1.646L25.59 5.841c-.44-.439-1.208-.439-1.647 0-.44.44-.44 1.208 0 1.647l2.964 2.854Z"
        />
        <path
            fill={fill}
            stroke={fill}
            strokeWidth={0.5}
            d="M23.614 36.69h1.207v1.207c0 .659.55 1.207 1.208 1.207.659 0 1.098-.548 1.098-1.207v-1.208h1.098c.658 0 1.207-.549 1.207-1.207 0-.659-.549-1.208-1.207-1.208h-1.098v-1.207c0-.659-.549-1.208-1.208-1.208-.658 0-1.207.549-1.207 1.208v1.207h-1.208c-.659 0-1.207.55-1.207 1.208.11.768.658 1.207 1.317 1.207Z"
        />
    </svg>
)
