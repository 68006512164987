import React from 'react';
import {Box, Modal} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
    position: 'absolute' as 'absolute',
    top:{xs:'50%',md:'50%'},
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    width: {xs:'95%',sm:'70%',md:'40%',lg:'40%'},
    padding: {xs:.9,md:2},
    bgcolor: 'background.paper',
    borderRadius: '8px',
    maxHeight: "calc(100vh - 150px)",
    overflowY: "hidden",
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 2,
    display: 'inline-flex',
};

const styleContentScroll = {
    width: '100%',
    overflowY: "auto",
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
    '::-webkit-scrollbar': {
        display: 'none',
    }
};

interface CustomModalI {
    open: boolean;
    handleClose: () => void;
    children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalI> = ({
                                                 open,
                                                 handleClose,
                                                 children,
                                             }) => {
    return (
        <Modal disableEnforceFocus={true} open={open} sx={{zIndex:3000}}>

            <Box sx={style}>
                <Box sx={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                    <Box component={"span"} sx={{
                        fontFamily: "Roboto",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal"
                    }} >
                        {"Ahorro anual"}
                    </Box>
                     <CloseIcon onClick={()=>{
                         handleClose()
                     }}/>
                </Box>
                <Box sx={styleContentScroll}>{children}</Box>
            </Box>
        </Modal>
    );
};
export default CustomModal;
