export interface SlideProps {
    image_desktop_1?:string
    image_mobile_1?: string;
    image_desktop_2?:string
    image_mobile_2?: string;
}
export const dummies_slider = ({image_desktop_1="",image_mobile_1="",image_desktop_2="",image_mobile_2=""}:SlideProps)=> {
    let array = [        {
        title: "¡Los mejores beneficios en un sólo lugar!",
        description: "Cuidamos desde de tu casa, tu salud y hasta tu mascota.",
        buttonTitle: "Conocer las asistencias",
        image: image_desktop_1,
        image_mobile: image_mobile_1,
        type: "1"
    },
        {
            title: "Boletos al 2x1 + combo cuates",
            description: "Las mejores promociones incluidas en tus beneficios plus",
            buttonTitle: "Conocer los beneficios",
            image: image_desktop_2,
            image_mobile: image_mobile_2,
            type: "2"
        }

    ]


    return array
}


