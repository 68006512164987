import React from 'react';

interface IconPhoneProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const IconPhoneCustom: React.FC<IconPhoneProps> = ({
                                                       width = 16,
                                                       height = 16,
                                                       fill = '#398C9C',
                                                       stroke = "#c7c7c7"
                                                   }) => {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector"
                  d="M12.7822 6.92444L10.8267 4.96889C10.5778 4.72 10.5067 4.37333 10.6044 4.06222C10.9333 3.06667 11.1111 2 11.1111 0.888889C11.1111 0.4 11.5111 0 12 0H15.1111C15.6 0 16 0.4 16 0.888889C16 9.23556 9.23556 16 0.888889 16C0.4 16 0 15.6 0 15.1111V12.0089C0 11.52 0.4 11.12 0.888889 11.12C1.99111 11.12 3.06667 10.9422 4.06222 10.6133C4.37333 10.5067 4.72889 10.5867 4.96889 10.8267L6.92444 12.7822C9.44 11.4933 11.5022 9.44 12.7822 6.92444Z"
                  fill={fill}/>
        </svg>
    );
};

export default IconPhoneCustom;
