import {useConfigurationStore} from "../../store/useConfigurationStore";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material"

import Telefono from "../../assets/images/telefono.svg"
import {PhoneColorSolicitar, PhoneIconColor, validatePhoneNumber} from "../../utils/utils";

export const SolicitarAsistencia = () => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const {selectedConfiguration} = useConfigurationStore()

    const callPhone = (phone:string) => {
        const phoneNumber = phone; // número de teléfono al que se realizará la llamada
        window.location.href = `tel:${phoneNumber}`;
    };

    return(
        <Box sx={{
            width: '100vw',
            display:'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '275px',
            justifyContent: 'center',
            paddingTop: isTablet ? '50px' : '',
            paddingBottom: isTablet ? '50px' : '',
            background: '#F4F4F4'
        }}>
            <Typography sx={{
                fontWeight: 700,
                fontSize: '32px',
                paddingLeft: isTablet ? '10px' : '',
                paddingRight: isTablet ? '10px' : '',
                textAlign: 'center'
            }}>
                Llámanos para solicitar tus asistencias
            </Typography>
            <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 700,
                fontSize: '32px'
            }}>
                al
                <Box component={'span'}
                     onClick={()=>{
                         isMobile ?
                             callPhone(selectedConfiguration?.phonenumber) :
                             isTablet &&  callPhone(selectedConfiguration?.phonenumber)
                     }}
                     sx={{
                    color: PhoneColorSolicitar(selectedConfiguration?.url,selectedConfiguration),
                    fontWeight: 700,
                    fontFamily:'Roboto',
                    fontSize: '32px',
                    marginLeft: '10px'
                }}>
                    {validatePhoneNumber(selectedConfiguration?.phonenumber)}
                </Box>
            </Typography>
            <Typography sx={{
                fontWeight: 400,
                fontSize: '20px',
                marginTop: '10px',
                marginBottom: isTablet ? '40px' : ''
            }}>
                Horario de atención las 24 hrs del día.
            </Typography>
            {
                isTablet && (
                    <Button
                        onClick={()=>{callPhone(selectedConfiguration?.phonenumber)}}
                        sx={{
                        background: PhoneIconColor(selectedConfiguration?.url,selectedConfiguration),
                        color: "#FFFFFF",
                        width: "340px",
                        height: "47px",
                        borderRadius: '50px',
                        textTransform: 'capitalize',
                        fontSize: '16px'
                    }}>
                        Solicitar asistencias
                        <Box
                            component="img"
                            sx={{
                                marginLeft: '10px'
                            }}
                            alt="Telefono icono"
                            src={Telefono}
                        />
                    </Button>
                )
            }
        </Box>
    )
}
