import {Box} from "@mui/material";
import {IconSalud} from "../../assets/IconsBenefitsDetail/IconSalud";
import {IconVial} from "../../assets/IconsBenefitsDetail/IconVial";
import {IconHogar} from "../../assets/IconsBenefitsDetail/IconHogar";
import {IconCine} from "../../assets/IconsBenefitsDetail/IconCine";
import {IconPlus} from "../../assets/IconsBenefitsDetail/IconPlus";
import {IconMascota} from "../../assets/IconsBenefitsDetail/IconMascota";


const BenefitsDescriptionCYA = () => {


    return (<Box sx={{
        width: '100%',
        height: '100%',
    }}>
        <Box sx={{
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        }}>
            {/* ***Header***/}

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    background: '#C6EFF6',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Asistencia
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    background: '#C6EFF6',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Límite anual de eventos
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    background: '#C6EFF6',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Monto de coberturas <br/>aproximado por evento
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    padding: '8px',
                    background: '#C6EFF6',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Costo aproximado por el<br/>total de eventos al año
                    </Box>
                </Box>

            </Box>
            {/* ***Header fin***/}


            {/* ***Vial***/}

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    display: 'flex'
                }}>
                    <Box sx={{width: 21.60, height: 16, position: 'relative'}}>
                        <IconVial/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Vial
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Grúa
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>3
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: "8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$1,000
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$3,000
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 46,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Cambio de llanta y<br/>paso de corriente
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Ilimitado
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$450
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$900
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>

                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Envío de gasolina
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>3
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$450
                    </Box>
                </Box>

                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: "8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$1,350
                    </Box>
                </Box>

            </Box>

            {/* ***Salud***/}

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{width: 21.60, height: 16, position: 'relative'}}>
                        <IconSalud/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Salud
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Check up
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>1
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: "10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$600
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$600
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    padding: '8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: {xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Interpretación de resultados
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>1
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$100
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$100
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Ambulancia
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>2
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$2,500
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$5,000
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 61,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Consultas sin costo con<br/>médicos especialistas<br/>de primer nivel
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>2
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$800
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$1,600
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Envío de medicamentos
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>1
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$350
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$350
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Cobertura dental
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>1
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Hasta $5,000
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$5,000
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                        <IconHogar/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Hogar
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 46,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Cerrajería, Plomería,<br/>Vidriería y Electricista
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>2
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$400
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$800
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                        <IconMascota/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Mascota
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 46,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Tele consulta<br/>médica veterinaria
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Ilimitado
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$300
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$300
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 46,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Consulta médica<br/>veterinaria en consultorio
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>1
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$250
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$250
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 31,
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Estética veterinaria
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>3
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$250
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$750
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                        <IconCine/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Cine
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Cinemex o Cinépolis
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Un beneficio mensual
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>2 folios de cine en<br/>sala tradicional
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding:"8px",
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$1,440
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '4px 8px 4px 8px',
                    background: '#EBFCFF',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                        <IconPlus/>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>Beneficios Plus
                    </Box>
                </Box>
            </Box>
            <Box sx={{alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding: {xs:'20px',sm:'16px',md:"16px"},
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Netflix
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Spotify
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Starbucks
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Uber
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                        }}>Krisp<br/> Kreme
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        height: 80,
                        padding: '4px 8px 4px 8px',
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cine Plus
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        height: 46,
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cinemex Platino
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        height: 46,
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cinépolis VIP
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Paramount
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Amazon
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        height:26,
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"9px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Amazon Prime Video
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '4px 8px 4px 8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Un beneficios al bimestre<br/>(No son acumulables)
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        height: {xs:'25px',sm:'auto'},
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de suscripción mensual
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $115
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $100
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $150
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio para canje en establecimiento
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        height:'76px',
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2 folios de cine en sala tradicional + 1 palomitas grandes y 2 refrescos
                            medianos
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        height: 46,
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2 folios de cine<br/>en sala platino
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        height: 46,
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2 folios de cine<br/>en sala VIP
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $79
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $150
                        </Box>
                    </Box>
                    <Box sx={{
                        alignSelf: 'stretch',
                        height:26,
                        padding:"8px",
                        border: '0.50px #398C9C solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:"10px",
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize:{xs:"10px",ms:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Folio de $99
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    alignSelf: 'stretch',
                    padding: '4px 8px 4px 8px',
                    border: '0.50px #398C9C solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize:{xs:"10px",ms:'12px'},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>$948
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 33,
                    padding:"8px",
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'right',
                        color: 'white',
                        fontSize: {xs:12,md:14},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Total anual, si ocupas todos los eventos
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 33,
                    padding:"8px",
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        color: 'white',
                        fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>$17,129
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding:"8px",
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'right',
                        color: 'white',
                        fontSize: {xs:12,md:14},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Costo anual de Asistencias Bradescard
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding:"8px",
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        color: 'white',
                        fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>$888
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                background:'red',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                display: 'inline-flex'
            }}>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding:"8px",
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        textAlign: 'right',
                        color: 'white',
                        fontSize: {xs:12,md:14},
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>Ahorro total al año
                    </Box>
                </Box>
                <Box sx={{
                    flex: '1 1 0',
                    height: 32,
                    padding: '8px',
                    background: '#398C9C',
                    border: '0.50px #7ECEDD solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap:"10px",
                    display: 'flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        color: 'white',
                        fontSize: 14,
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        wordWrap: 'break-word'
                    }}>$16,241
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                alignSelf: 'stretch',
                height: 32,
                padding: '4px 8px 4px 8px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap:"10px",
                display: 'inline-flex'
            }}>
                <Box sx={{
                    textAlign: 'center',
                    color: '#212121',
                    fontSize: '8px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    wordWrap: 'break-word'
                }}>*El ahorro total al año es aproximado, depende del uso de asistencias y beneficios por parte del
                    usuario
                </Box>
            </Box>
        </Box>
    </Box>)
}
export default BenefitsDescriptionCYA;
