import React, {useEffect, useState} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import AccordeonCustomized from "../../components/AccordeonCustomized/AccordeonCustomized";
import {useConfigurationStore} from "../../store/useConfigurationStore";
import {getFaqs} from "../../api/getFaqs";

export interface FAQSI {
    name?: string;
    partner?: string;
}

export const preguntas = ({name="", partner=""}: FAQSI) => {
    let array = [
        {
            title: `¿Qué es ${name}?`,
            description: `${name} es un programa que puedes contratar con tu terjeta bradescard Shasa, para cuidar tu binestar, tendrás coberturas integrales que te cubren desde medicina preventiva, auxilio vial, asistencias hogar, mascota, boletos de Cine y mas.`
        },
        {
            title: `¿Cómo puedo acceder al ${name}?`,
            description: `¡Es muy fácil! Sólo necesitas contar con tu tarjeta de bradescard (Nombre del socio) y esperar la invitación en APP`
        },
        {
            title: `¿Qué son los beneficios plus?`,
            description: `Sabemos que el entretenimiento es una parte importante de nuestro bienestar, por eso, hemos incluido cupones que puedes canjear por suscripciones de streaming, alimentos e incluso boletos de cine`
        },
        {
            title:"¿Cómo funcionan los beneficios plus?",
            description:'Te enviaremos un cupón que deberas canjear ya sea dentro de la aplicación o dentro del establecimiento para hacerlo valido, recuerda que solo puedes seleccionar uno a la vez y al finalizar su vigencia podras hacer uso de uno nuevo.'
        },
        {
            title:"¿Cuál es la vigencia del plan?",
            description: 'La vigencia es ilimitada mientras decidas continuar con la protección, y los eventos se renuevan cada año.'
        },
        {
            title:"¿Cómo se realiza el pago del plan?",
            description: 'El cargo se genera de forma automatica a tu tarjeta de crédito Bradescard (Nombre del socio).'
        },
        {
            title: '¿Como puedo hacer uso de mis beneficios?',
            description: 'Una vez que hayas adquirido el programa  puedes comunicarte al 55 8872 7456 o bien, tendras acceso al portal de cliente donde podras visualizar todas tus coberturas y solicitarlas.'
        }

    ]


    return array
}


export const PreguntasFrecuentes = () => {
    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const { selectedConfiguration } = useConfigurationStore();
    const [loading,setLoading]=useState(selectedConfiguration.loading)

    const [data,setData]=useState<FAQSI[]>([])

    useEffect(() => {
        if (selectedConfiguration && selectedConfiguration?.id){
            setLoading(true)
            getFaqs(selectedConfiguration?.id).then((res:any)=>{
                if (res.status===200){
                    setLoading(false)
                    setData(res.data)

                }

            }).catch(e=>{

            })
        }

    }, [selectedConfiguration?.id]);

    return (

        selectedConfiguration?.id && data.length > 0 ?
        <Box sx={{
            display: selectedConfiguration?.id? 'flex' : 'none',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '80px',
            paddingBottom: '80px'
        }}>
            {
                !loading && data.length > 0 &&
                    <AccordeonCustomized data={data}/>
            }
        </Box>
            :
            null

    )
}
