import { InterInstance } from './config';

export const getBenefit = async (id: string,tag:string="all") => {
  try {
    const  data  = await InterInstance.get(`benefit?companyId=${id}&tag=${tag}`, {
      //headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (e) {
    return e;
  }
};
