import React, {useEffect, useState} from "react";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import Swiper React components
import {Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Swiper.css';
import ItemSwiper from "./ItemSwiper";
import {useConfigurationStore} from "./../../store/useConfigurationStore";
import {getPromoBanner} from "../../api/getPromoBanner";


export interface ListSlideItem {
    createTime: string;
    updateTime: string,
    deteleTime: string | null;
    id: string;
    order?:undefined|number;
    companyId: string;
    title: string;
    description: string;
    images: string[];
    color?: string;
}

const ListSlider: React.FC = () => {
    const {selectedConfiguration} = useConfigurationStore();

    const {breakpoints} = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('lg'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const [loading, setLoading] = useState(selectedConfiguration.loading)

    const [promos, setPromos] = useState<ListSlideItem[]>([])

    useEffect(() => {
        if (selectedConfiguration && selectedConfiguration?.id) {
            setLoading(true)
            getPromoBanner(selectedConfiguration?.id).then((res: any) => {
                //alert(JSON.stringify(res))
                if (res.status === 200) {
                    setLoading(false)
                    setPromos(res.data)
                    //alert(JSON.stringify(res.data))

                }

            }).catch(e => {

            })
        }

    }, [selectedConfiguration?.id]);

    return (
        selectedConfiguration?.id && promos.length > 0 ?
            <Box sx={{justifyContent: 'center', display: 'flex'}}>
                {
                    !loading && promos.length > 0 ?
                        <Box className="container-box" sx={{
                            width: {xs: '100%', lg: '1140px'}
                        }}>
                            <div className="small-container">

                                <Swiper
                                    autoHeight={true}
                                    spaceBetween={1}
                                    //navigation={isTablet ? false : true}
                                    // pagination={{
                                    //     clickable: true,
                                    //     enabled: isDesktop ? false : true
                                    // }}

                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                        enabled: isDesktop ? false : true
                                    }}
                                    navigation={{nextEl: ".arrow-right", prevEl: ".arrow-left"}}
                                    modules={[Navigation, Pagination]}
                                    className="Swiper"
                                    style={{
                                        "--swiper-navigation-color": "#000000",
                                        "--swiper-pagination-color": 'rgba(0,0,0,0.71)',
                                        position: 'relative',
                                        width: '100%'
                                    }}
                                >


                                    {promos && promos?.length > 0 && promos.sort((a:any,b:any) => a?.order - b?.order).map((component_: any, index: any) => {
                                            return (
                                                <SwiperSlide className="Swiper-slide"
                                                             style={{
                                                                 height:
                                                                 isMobile ? '610px'
                                                                     :
                                                                     isTablet ?
                                                                         '330px'
                                                                         :
                                                                         isDesktop  ?
                                                                             '486px'
                                                                             :
                                                                                 '446px'

                                                             }}
                                                             key={index}>
                                                    <ItemSwiper item={component_} brand={selectedConfiguration}
                                                                index={index}/>
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                                </Swiper>

                                <Box component={'button'} className="arrow-left arrow" sx={{
                                    width: "48px",
                                    marginLeft: '16px',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    height: "48px",
                                    borderWidth: 0,
                                    borderRadius: '48px',
                                    flexShrink: 0,
                                    fill: "#F4F4F4",
                                    opacity: 0.7,
                                    display: {xs: 'none', md: promos.length > 1 ? 'flex' : 'none'}
                                }}><ArrowBackIosNewIcon/></Box>
                                <Box component={'button'} className="arrow-right arrow" sx={{
                                    width: "48px",
                                    height: "48px",
                                    marginRight: '16px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderWidth: 0,
                                    borderRadius: '48px',
                                    flexShrink: 0,
                                    fill: "#F4F4F4",
                                    opacity: 0.7,
                                    display: {xs: 'none', md: promos.length > 1 ? 'flex' : 'none'}
                                }}><ArrowForwardIosIcon sx={{color: '#000'}}/></Box>
                            </div>

                        </Box>


                        :
                        loading ?
                            <Box sx={{
                                display: 'flex',
                                width: {xs: '100%', md: '461px'},
                                height: '396.172px',
                                padding: {xs: '16px 0px 10px 0px', md: '16px 8px'},
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                gap: '12px',
                                flexShrink: 0,
                                borderRadius: '16px',
                                background: '#FFF'
                            }}>
                                <Box sx={{display: 'flex'}}>
                                    <CircularProgress
                                        sx={{color: selectedConfiguration ? selectedConfiguration?.primaryColor : '#004876'}}/>
                                </Box>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                width: {xs: '100%', md: '461px'},
                                height: '396.172px',
                                padding: {xs: '16px 0px 10px 0px', md: '16px 8px'},
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                gap: '12px',
                                flexShrink: 0,
                                borderRadius: '16px',
                                background: '#FFF'
                            }}>
                                <Typography
                                    sx={{
                                        color: 'var(--black-75, #595959)',
                                        fontFamily: 'Montserrat',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        maxWidth: '267px'
                                    }}
                                >
                                    {"Aun no cuentas con info..."}
                                </Typography>
                            </Box>
                }

            </Box>
            :
            null
    )
}

export default ListSlider;
