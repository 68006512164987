import * as React from "react"

interface Props{
    fill: string
}
export const IconEntertainment = ({fill}:Props) => (

    <svg width="20" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path id="Vector"
                  d="M19.8183 7.9055L18.4603 6.54542C18.0362 6.83089 17.5447 6.97363 17.0513 6.97363C16.4049 6.97363 15.7585 6.7269 15.265 6.23344C14.7716 5.73998 14.5248 5.09358 14.5248 4.44719C14.5248 3.95576 14.6696 3.46434 14.9531 3.04021L13.593 1.68013L11.1155 4.15967L11.8067 4.85093L11.1155 5.54218L10.4222 4.85093L2.18013 13.093L3.54021 14.4531C3.96434 14.1676 4.45576 14.0248 4.94719 14.0248C5.59358 14.0248 6.23794 14.2716 6.7314 14.765C7.22486 15.2585 7.47363 15.9049 7.47363 16.5513C7.47363 17.0447 7.3309 17.5362 7.04746 17.9603L8.4055 19.3183L16.6496 11.0763L15.9522 10.381L16.6435 9.68971L17.3408 10.385L19.8183 7.9055ZM15.0224 9.45114L13.9845 8.41324L14.6757 7.72198L15.7136 8.75785L15.0224 9.45114ZM13.0873 7.51399L12.0494 6.47609L12.7406 5.78484L13.7785 6.82274L13.0873 7.51399ZM18.8396 5.54218L20.8562 7.56089C21.0479 7.75053 21.0479 8.06047 20.8562 8.25215L8.75215 20.3562C8.56047 20.5479 8.25053 20.5479 8.06089 20.3562L6.04218 18.3396C5.85255 18.1479 5.85255 17.838 6.04218 17.6483C6.34601 17.3465 6.4969 16.9489 6.4969 16.5513C6.4969 16.1557 6.34601 15.7581 6.04218 15.4563C5.73836 15.1545 5.34481 15.0036 4.94719 15.0036C4.5516 15.0036 4.15398 15.1545 3.85219 15.4563C3.66052 15.648 3.35057 15.648 3.16094 15.4563L1.14223 13.4396C0.952591 13.2479 0.952591 12.938 1.14223 12.7484L13.2484 0.642227C13.438 0.452591 13.7479 0.452591 13.9396 0.642227L15.9563 2.66094C16.148 2.85057 16.148 3.16052 15.9563 3.35219V3.35015C15.6525 3.65398 15.5016 4.0516 15.5016 4.44719C15.5016 4.84277 15.6545 5.2404 15.9563 5.54218C16.2601 5.84601 16.6557 5.9969 17.0513 5.9969C17.4469 5.9969 17.8445 5.84601 18.1483 5.54218C18.338 5.35255 18.6479 5.35255 18.8396 5.54218Z"
                  fill={fill} stroke={fill} strokeWidth="0.5"/>
        </g>
    </svg>
)
