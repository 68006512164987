import { InterInstance } from './config';

export const getCompany = async (id: string) => {
  try {
    const  data  = await InterInstance.get(`company/${id}`, {
      //headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (e) {
    return e;
  }
};
