import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Pages from "./navigation/Pages";
import {Box, createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {themeValues} from "../src/theme/theme";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

let theme = createTheme(themeValues);
theme = responsiveFontSizes(theme);

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <div>
                    <main style={{
                        width: '100%',
                        overflow: 'auto',
                    }}>
                        <Box id="main-box" component="main" sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Header/>
                            <Pages/>
                            <Footer/>
                        </Box>
                    </main>
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
