import * as React from "react";

interface PropsI {
    fill?: string;
    stroke?:string;
}

export const IconPlus = ({fill = "#398C9C",stroke='#398C9C'}: PropsI) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M8 16C6.52978 16 5.33333 14.8036 5.33333 13.3333L5.38045 10.6196L2.68267 10.6667C1.19644 10.6667 0 9.47022 0 8C0 6.52978 1.19644 5.33333 2.66667 5.33333L5.38045 5.28533L5.33333 2.68267C5.33333 1.19644 6.52978 0 8 0C9.47022 0 10.6667 1.19644 10.6667 2.66667L10.7156 5.28533L13.3493 5.33333C14.8036 5.33333 16 6.52978 16 8C16 9.47022 14.8036 10.6667 13.3333 10.6667L10.7156 10.6196L10.6667 13.3493C10.6667 14.8036 9.47022 16 8 16ZM7.11111 8.88889V13.3493C7.11111 13.8231 7.51022 14.2222 8 14.2222C8.48978 14.2222 8.88889 13.8231 8.88889 13.3333V8.88889H13.3493C13.8231 8.88889 14.2222 8.48978 14.2222 8C14.2222 7.51022 13.8231 7.11111 13.3333 7.11111H8.88889V2.66667C8.88889 2.16089 8.48978 1.77778 8 1.77778C7.51022 1.77778 7.11111 2.17689 7.11111 2.66667V7.11111H2.66667C2.16089 7.11111 1.77778 7.51022 1.77778 8C1.77778 8.48978 2.17689 8.88889 2.66667 8.88889H7.11111Z"
            fill={fill}/>
    </svg>
)
