import {Box} from "@mui/material";
import {IconSalud} from "../../assets/IconsBenefitsDetail/IconSalud";
import {IconVial} from "../../assets/IconsBenefitsDetail/IconVial";
import {IconHogar} from "../../assets/IconsBenefitsDetail/IconHogar";
import {IconCine} from "../../assets/IconsBenefitsDetail/IconCine";
import {IconPlus} from "../../assets/IconsBenefitsDetail/IconPlus";
import {IconMascota} from "../../assets/IconsBenefitsDetail/IconMascota";


const BenefitsDescriptionDelSol = () => {


    return (
        <Box sx={{
            width: '100%',
            height: '100%',
        }}>

            <Box sx={{
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        background: '#F2B6BB',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Asistencia
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        background: '#F2B6BB',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Límite anual de eventos
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        background: '#F2B6BB',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Monto de coberturas aproximado por evento
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        background: '#F2B6BB',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Costo aproximado por el total de eventos al año
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{width: 21.60, height: 16, position: 'relative'}}>
                            <IconVial fill={"#C72632"} stroke={"#C72632"}/>
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Vial
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Grúa
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>3
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$1,000
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$3,000
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cambio de llanta y<br/>paso de corriente
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Ilimitado
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$450
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$900
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Envío de gasolina
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>3
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$450
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$1,350
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>

                        <Box sx={{width: 21.60, height: 16, position: 'relative'}}>
                            <IconSalud fill={"#C72632"} />
                        </Box>

                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Salud
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Check up
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>1
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$600
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$600
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Interpretación<br/>de resultados
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>1
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$100
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$100
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Ambulancia
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$2,500
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$5,000
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 61,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Consultas sin costo con<br/>médicos especialistas<br/>de primer nivel
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$800
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$1,600
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Envío de medicamentos
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>1
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$350
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$350
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cobertura dental
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>1
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Hasta $5,000
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$5,000
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                                <IconHogar fill={"#C72632"} stroke={"#C72632"} />
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Hogar
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cerrajería, Plomería,<br/>Vidriería y Electricista
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$400
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$800
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                            <IconMascota fill={"#C72632"} stroke={"#C72632"} />
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Mascota
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Tele consulta<br/>médica veterinaria
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Ilimitado
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$300
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$300
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 46,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Consulta médica<br/>veterinaria en consultorio
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>1
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$250
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$250
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 31,
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Estética veterinaria
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>3
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$250
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$750
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                            <IconCine fill={"#C72632"} stroke={"#C72632"} />
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Cine
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Cinemex o Cinépolis
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Un beneficio mensual
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>2 folios de cine en<br/>sala tradicional
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding: '8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$1,440
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#FEE9EA',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{width: 22.32, height: 16, position: 'relative'}}>
                            <IconPlus fill={"#C72632"} stroke={"#C72632"} />
                        </Box>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>Beneficios Plus
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Netflix
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Spotify
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Starbucks
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Uber
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            height: 46,
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Krispy Kreme
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            height: 76,
                            padding:'8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Cine Plus
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            height: 46,
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Nutrisa
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Recarga telefónica<br/>(Telcel, Movistar, AT&T)
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding:'8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Un beneficios al bimestre<br/>(No son acumulables)
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        display: 'inline-flex'
                    }}>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de suscripción mensual
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de $115
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de $100
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de $150
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio para canje<br/>en establecimiento
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>2 folios de cine en<br/>sala tradicional +<br/>1 palomitas grandes y <br/>2 refrescos
                                medianos
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de $100 para canje<br/>en establecimiento
                            </Box>
                        </Box>
                        <Box sx={{
                            alignSelf: 'stretch',
                            flex: '1 1 0',
                            padding: '8px',
                            border: '0.50px #C72632 solid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            display: 'inline-flex'
                        }}>
                            <Box sx={{
                                textAlign: 'center',
                                color: '#212121',
                                fontSize: {xs:'10px',md:'12px'},
                                fontFamily: 'Montserrat',
                                fontWeight: '400',
                                wordWrap: 'break-word'
                            }}>Folio de $100
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        alignSelf: 'stretch',
                        padding:'8px',
                        border: '0.50px #C72632 solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            textAlign: 'center',
                            color: '#212121',
                            fontSize: {xs:'10px',md:'12px'},
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>$1,074
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 33,
                        padding: '8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'right',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Total anual, si ocupas todos los eventos
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 33,
                        padding: '8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>$22,614
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding: '8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'right',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Costo anual de Asistencias Bradescard
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>$888
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding: '8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            textAlign: 'right',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>Ahorro total al año
                        </Box>
                    </Box>
                    <Box sx={{
                        flex: '1 1 0',
                        height: 32,
                        padding:'8px',
                        background: '#C72632',
                        border: '0.50px #F2B6BB solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}>
                        <Box sx={{
                            flex: '1 1 0',
                            color: 'white',
                            fontSize: 14,
                            fontFamily: 'Montserrat',
                            fontWeight: '700',
                            wordWrap: 'break-word'
                        }}>$21,726
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    alignSelf: 'stretch',
                    height: 32,
                    padding:'8px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '10px',
                    display: 'inline-flex'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#212121',
                        fontSize: 8,
                        fontFamily: 'Montserrat',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }}>*El ahorro total al año es aproximado, depende del uso de asistencias y beneficios por parte del
                        usuario
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default BenefitsDescriptionDelSol;
