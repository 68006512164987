import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";

import {useConfigurationStore} from "../../store/useConfigurationStore";
import {CorazonIcon} from "../JSXIcons/CorazonIcon";
import {DienteIcon} from "../JSXIcons/DienteIcon";
import {AmbulanciaIcon} from "../JSXIcons/AmbulanciaIcon";
import {HogarIcon} from "../JSXIcons/HogarIcon";
import {GruaIcon} from "../JSXIcons/GruaIcon";
import {MascotaIcon} from "../JSXIcons/MascotaIcon";
import CloseIcon from '../../assets/Icons/CloseIcon.svg'

import './Asistencias.css';
import {getSupport} from "../../api/getSupport";
import {AsistenciaDetalle} from "../AsistenciaDetalle";
import {styled} from "styled-components";
import {eventScrollDown, IconColor, VerMasColor} from "../../utils/utils";

interface Asistencia {
    createTime: string
    updateTime: string
    deteleTime: string | null
    id: string
    companyId: string
    title: string
    detail: string
    description: string
    image: string
}


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:  '#1A2027',
    padding: 32,
    textAlign: 'center',
    color: 'red',
}));

export const Asistencias = () => {
    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isDesktop = useMediaQuery(breakpoints.up('lg'));

    const {selectedConfiguration} = useConfigurationStore()
    const [showInfo, setShowInfo] = useState(false)
    const [selectedCard, setSelectedCard] = useState("")
    const [asistencias, setAsistencias] = useState<Asistencia[]>([])
    const [selectedDetail, setSelectedDetail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const getData = async () => {
        setIsLoading(true)
        const data = await getSupport(selectedConfiguration.id)
        setAsistencias(data)
    }

    useEffect(() => {
        getData()
        setIsLoading(false)
    }, [selectedConfiguration])

    const gallerySlider = document.getElementById('gallerySliderContaineras');
    const sliderTotalWidth =
        (gallerySlider?.scrollWidth || 0) - (gallerySlider?.clientWidth || 0);
    const numberOfCards = asistencias.length;
    const sectionWidth = sliderTotalWidth / numberOfCards;

    gallerySlider?.addEventListener('scroll', (e) => {
        const scrollLeft = gallerySlider?.scrollLeft;
        asistencias.forEach((e, i) => {
            if (
                sectionWidth * i <= scrollLeft &&
                scrollLeft <= sectionWidth * (i + 1)
            ) {
                //document.getElementById(`indicator-as-${i}`)?.classList.add('selected-as');
                const indicator_: any = document.getElementById(`indicator-as-${i}`);
                indicator_.style.backgroundColor = selectedConfiguration?.primaryColor;
                indicator_.style.width = '40px';
            } else {
                //document.getElementById(`indicator-as-${i}`)?.classList.remove('selected-as');
                const indicator: any = document.getElementById(`indicator-as-${i}`);
                indicator.style.backgroundColor = '#D9D9D9';
                indicator.style.width = '20px';

            }
        });
    });

    let pos = {left: 0, x: 0};

    const mouseMoveHandler = (e: any) => {
        const dx = e.clientX - pos.x;

        if (gallerySlider !== null) {
            gallerySlider.scrollLeft = pos.left - dx;
        }
    };

    const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        if (gallerySlider !== null) {
            gallerySlider.style.cursor = 'grab';
            gallerySlider.style.removeProperty('user-select');
        }
    };

    const mouseDownHandler = (e: any) => {
        pos = {
            left: gallerySlider?.scrollLeft || 0,
            x: e.clientX,
        };

        if (gallerySlider !== null) {
            gallerySlider.style.cursor = 'grabbing';
            gallerySlider.style.userSelect = 'none';
        }

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const handleClickIndicator = (indicatorNumber: number) => {
        if (gallerySlider !== null) {
            if (indicatorNumber + 1 === numberOfCards) {
                gallerySlider.scrollLeft = sliderTotalWidth;
            } else {
                gallerySlider.scrollLeft = indicatorNumber * sectionWidth + 1;
            }
        }
    };

    if (asistencias.length < 0 || isLoading || selectedConfiguration.loading) {
        return <></>
    }

    const getIcon = (image: string, title: string) => {
        if (image === "") {
            const {url} = selectedConfiguration
            if (title === "Médica") return <CorazonIcon fill={IconColor(url, selectedConfiguration)}/>
            if (title === "Ambulancia") return <AmbulanciaIcon fill={IconColor(url, selectedConfiguration)}/>
            if (title === "Hogar") return <HogarIcon fill={IconColor(url, selectedConfiguration)}/>
            if (title === "Dental") return <DienteIcon fill={IconColor(url, selectedConfiguration)}/>
            if (title === "Vial") return <GruaIcon fill={IconColor(url, selectedConfiguration)}/>
            if (title === "Mascotas") return <MascotaIcon fill={IconColor(url, selectedConfiguration)}/>
        } else {
            return <Box
                component="img"
                sx={{
                    width: '60px',
                    height: '60px'
                }}
                alt="Icono icono"
                src={image}
            />
        }
    }

    return (
        <Box
            id={"section-asistencias"}
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
            <Box sx={{
                width: '90%',
                maxWidth: '1140px',
                display: 'flex',
                position:'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: '100px',
                paddingTop: '100px',
            }}>

                <Typography sx={{
                    fontSize: '32px',
                    fontWeight: 700,
                    textAlign: 'center',
                    lineHeight: '37.5px',
                    marginBottom: isDesktop ? "30px" : ""
                }}>
                    Además, tendrás asistencias que te acompañan en todo momento
                </Typography>
                {
                    isTablet ? (
                        <>
                            <div
                                className="gallery-slider-container-as"
                                id="gallerySliderContaineras"
                                onMouseDown={mouseDownHandler}
                            >
                                <div className="gallery-slider-as" id="gallery-as">
                                    {asistencias.length >0 && asistencias.map((asistencia) => (
                                        <Box
                                            key={asistencia.id}
                                            sx={{
                                                background: '#F7F7F7',
                                                width: '290px',
                                                height: '276px',
                                                margin: '10px',
                                                padding: '24px',
                                                borderRadius: '8px',
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: '0px'
                                            }}
                                        >
                                            <Box sx={{display: "flex",
                                                width: "70px",
                                                height: "60px",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px"}}>
                                                {getIcon(asistencia.image, asistencia.title)}
                                            </Box>

                                            <Typography sx={{
                                                flex: 0,
                                                fontSize: '22px',
                                                fontWeight: 600,
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}>
                                                {asistencia.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    textAlign: {xs: 'center', sm: 'left'},
                                                    fontSize: '14px',
                                                    lineHeight: "24px",
                                                    fontWeight: 400,
                                                    color: '#505050',
                                                }}
                                                dangerouslySetInnerHTML={{__html: asistencia.description}}
                                            />
                                            <Typography sx={{
                                                flex: 0,
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                color: asistencia.title === selectedCard ? "#808080" : VerMasColor(selectedConfiguration.url, selectedConfiguration),
                                                textAlign: 'end',
                                                marginTop: '35px',
                                                cursor: 'pointer',
                                            }}
                                                        onClick={() => {
                                                            if ( asistencia.title === selectedCard){
                                                                setShowInfo(false)
                                                                setSelectedCard("")
                                                                setSelectedDetail("")
                                                            }else {
                                                                setShowInfo(true)
                                                                setSelectedCard(asistencia.title)
                                                                setSelectedDetail(asistencia.detail)
                                                                setTimeout(()=>{
                                                                    eventScrollDown(  "detalle-asistencia")
                                                                },500)
                                                            }
                                                        }}
                                            >
                                                {asistencia.title === selectedCard  ? "Ver más" : "Ver más"}
                                            </Typography>
                                        </Box>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <Grid container spacing={0} sx={{
                            alignItems:'center',
                            width: {xs: 'auto', sm: 'auto', md: 'auto', lg: '98%'},
                            justifyContent:'center',
                            display:'flex',
                        }}>

                            {
                                asistencias.length >0 &&
                                asistencias.map((asistencia, index) => (
                                    <Grid key={asistencia.id} item xs={4} sx={{alignItems:'center',display:'flex',justifyContent:'center'}}>
                                        <Box
                                            key={asistencia.id}
                                            sx={{
                                                background: '#F7F7F7',
                                                width: {xs: 'auto', sm: 'auto', md: '100%', lg: '100%'},
                                                height: {xs: '276px'},
                                                margin:1,
                                                padding: '20px',
                                                borderRadius: '8px',
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Box sx={{display: "flex",
                                                width: "70px",
                                                height: "60px",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px"}}>
                                                {getIcon(asistencia.image, asistencia.title)}
                                            </Box>
                                            <Typography sx={{
                                                flex: 0,
                                                fontSize: '22px',
                                                fontWeight: 600,
                                                marginTop: '10px',
                                            }}>
                                                {asistencia.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    textAlign: {xs: 'center', sm: 'left'},
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    color: '#505050',
                                                }}
                                                dangerouslySetInnerHTML={{__html: asistencia.description}}
                                            />
                                            <Typography sx={{
                                                flex: 0,
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                color: asistencia.title === selectedCard ? "#808080" : VerMasColor(selectedConfiguration.url, selectedConfiguration),
                                                textAlign: 'end',
                                                marginTop: '0px',
                                                cursor: 'pointer'
                                            }}
                                                        onClick={() => {
                                                            if ( asistencia.title === selectedCard){
                                                                setShowInfo(false)
                                                                setSelectedCard("")
                                                                setSelectedDetail("")
                                                            }else {
                                                                setShowInfo(true)
                                                                setSelectedCard(asistencia.title)
                                                                setSelectedDetail(asistencia.detail)
                                                                setTimeout(()=>{
                                                                    eventScrollDown(  "detalle-asistencia","center")
                                                                },500)

                                                            }
                                                        }}
                                            >
                                                {asistencia.title === selectedCard  ? "Ver más" : "Ver más"}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    )
                }
                {
                    showInfo && (
                        <Box
                            id={"detalle-asistencia"}
                            sx={{
                            margin:{xs:0,sm:1,md:1,lg:2},
                            width: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto'},
                            background: '#F7F7F7',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            marginTop: '30px',
                            borderRadius: '8px',
                            padding: '30px',
                            position: 'relative',
                            paddingBottom: '40px'
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                right: '30px',
                                top: '20px',
                                cursor: 'pointer'
                            }}
                                 component={'img'}
                                 src={CloseIcon}
                                 alt={'Close icon'}
                                 onClick={() => {
                                     setShowInfo(false)
                                     setSelectedCard("")
                                     eventScrollDown("section-asistencias")
                                 }}
                            />
                            <Typography sx={{
                                fontSize: '22px',
                                fontWeight: 600,
                                marginBottom: '14px'
                            }}>{selectedCard}</Typography>
                            <AsistenciaDetalle detail={selectedDetail}/>
                        </Box>
                    )
                }
                {
                    isTablet && (
                        <div className="indicator-slider-container-as">
                            {asistencias.map((e, i) => (
                                <div
                                    key={i}
                                    id={`indicator-as-${i}`}
                                    className={'indicator-as'}
                                    onClick={() => handleClickIndicator(i)}
                                ></div>
                            ))}
                        </div>
                    )
                }
            </Box>
        </Box>
    )
}
