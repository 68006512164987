import React, {useEffect} from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";

interface Props {
    detail: string
}

export const AsistenciaDetalle = ({detail}: Props) => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));

    useEffect(()=> {
        let firstContainer = document.getElementById('firstContainer');
        let secondContainer = document.getElementById('secondContainer');
        firstContainer && (firstContainer.style.width = '100%');
        secondContainer && (secondContainer.style.width = '100%');
    },[isTablet, detail])

    return(
        <Box sx={{
            display: 'flex',
            flexDirection: isTablet ? 'column' : 'row',
            justifyContent: 'space-between'
        }}>
            <Box sx={{
                paddingRight: '20px',
            }}>
                <Box
                    sx={{
                        display:'flex',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#505050',
                        flexDirection: isTablet ? 'column' : 'row',
                    }}
                    dangerouslySetInnerHTML={{__html: detail}}
                />
            </Box>
        </Box>
    )
}