import * as React from "react"

interface Props{
    fill: string
}
export const IconAll = ({fill}:Props) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0.5H6.21429V6.21429H0.5V0.5Z" fill={fill}/>
        <path d="M14.7856 0.5H20.4999V6.21429H14.7856V0.5Z" fill={fill}/>
        <path d="M7.64282 0.5H13.3571V6.21429H7.64282V0.5Z" fill={fill}/>
        <path d="M0.5 14.7858H6.21429V20.5001H0.5V14.7858Z" fill={fill}/>
        <path d="M14.7856 14.7858H20.4999V20.5001H14.7856V14.7858Z" fill={fill}/>
        <path d="M7.64282 14.7858H13.3571V20.5001H7.64282V14.7858Z" fill={fill}/>
        <path d="M0.5 7.64282H6.21429V13.3572H0.5V7.64282Z" fill={fill}/>
        <path d="M14.7856 7.64282H20.4999V13.3572H14.7856V7.64282Z" fill={fill}/>
        <path d="M7.64282 7.64282H13.3571V13.3572H7.64282V7.64282Z" fill={fill}/>
    </svg>
)
